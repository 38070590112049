
body {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  min-height: 100vh;
}
.chucky{
  min-height: calc( 100vh - 123px );
  display: flex;
}
.buttons{
  
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  

}
