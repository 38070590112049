.css-1cndkqf-MuiToolbar-root {
    justify-content: center;
}

.avatar {
    width: 75px;
    height: 75px;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 640px) {
    .avatar {
        width: 30px;
        height: 30px;
        margin-top: -30px;
        background-size: 15px 15px;
        /* bottom: -20px  */
    }
}

.header {
    width: 100%
}

@media screen and (max-width:640px) {
    .header {
        width: 100% !important;
    }
}

.avatar-span {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-bottom: 60px;
}

@media (max-width: 640px) {
    .avatar-span {
        width: 50px;
        height: 50px;
        margin-top: -15px;
        background-size: 15px 15px;
        margin-right: 40px;
        bottom: -15px
    }
}

.logo-header {
    padding-left: 17px;
    display: flex;
    position: relative;
    z-index: 1000;
    width: 260px;
}

@media screen and (max-width: 640px) {
    .logo-header {
        margin-left: 0;
        padding-left: 5px!important;
        width: 95%!important;
        padding-bottom: 10px;
    }
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.MuiGrid-grid-lg-2 {
    padding-left: 17px;
    display: flex;
    position: relative;
    z-index: 1000;
    width: 260px;
    height: 100;
}

@media screen and (max-width: 640px) {
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.MuiGrid-grid-lg-2 {
        width: 100%!important;
        /* margin: auto; */
        margin-left: 0px;
        padding-left: 0 !important;
    }
}